import React from "react";
import {navigate} from "gatsby";

class News extends React.Component {

	componentDidMount() {
		navigate('/news/all');
	}

	render() {
		return (
			<div></div>
		);
	}
}

export default News;